import { NgModule } from "@angular/core";
import {
  ExtraOptions,
  RouterModule,
  Routes,
  mapToCanActivate,
} from "@angular/router";
import { ComentariosComponent } from "./admin/comentarios/comentarios.component";
import { UserType } from "./enums/UserTypes";
import { PerfilUsuariosComponent } from "./perfil-usuarios/perfil-usuarios.component";
import {
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings,
  ManualParserLoader,
} from "@gilsdav/ngx-translate-router";
import { TranslateService } from "@ngx-translate/core";
import { Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Path } from "./enums/Path";
import { AuthGuard, redirectUnauthorizedTo } from "@angular/fire/auth-guard";
import { ValidadorComponent } from "./validador/validador.component";
import { AlianzasComponent } from "./alianzas/alianzas.component";
import { AccessGuard } from "./guards/access.guard";
import { IndexComponent } from "./index/index.component";
import { ResponsabilidadSocialComponent } from "./responsabilidad-social/responsabilidad-social.component";
import { ContactenosComponent } from "./contactenos/contactenos.component";
import { EnlacesDeInteresComponent } from "./enlaces-de-interes/enlaces-de-interes.component";
import { DetalleDeProductoComponent } from "./detalle-de-producto/detalle-de-producto.component";
import { CarroComprasComponent } from "./carro-compras/carro-compras.component";
import { PublicidadComponent } from "./admin/publicidad/publicidad.component";
import { CertificacionComponent } from "./certificacion/certificacion.component";
import { NoticiasComponent } from "./noticias/noticias.component";
import { CatalogoComponent } from "./catalogo/catalogo.component";
import { CategoriasComponent } from "./categorias/categorias.component";

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo([Path.Login]);

export const manualLoaderFactory = (
  translate: TranslateService,
  location: Location,
  settings: LocalizeRouterSettings
) => {
  return new ManualParserLoader(translate, location, settings, ["es", "en"]);
};

const routerOptions: ExtraOptions = {
  anchorScrolling: "enabled",
  scrollPositionRestoration: "enabled",
};

const routes: Routes = [
  {
    path: "",
    component: IndexComponent,
  },
  {
    path: Path.Publicidad,
    component: PublicidadComponent,
  },
  {
    path: Path.Validador,
    component: ValidadorComponent,
  },
  {
    path: Path.Comentarios,
    component: ComentariosComponent,
  },
  {
    path: Path.Alianzas,
    component: AlianzasComponent,
  },
  {
    path: Path.Categorias,
    component: CategoriasComponent,
  },
  {
    path: Path.Home,
    component: IndexComponent,
  },
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: Path.Administrador,
    loadChildren: () =>
      import("./admin/admin.module").then((m) => m.AdminModule),
  },
  {
    path: Path.PerfilUsuarios,
    component: PerfilUsuariosComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./perfil-usuarios/perfil-usuarios.module").then(
            (m) => m.PerfilUsuariosModule
          ),
      },
    ],
    canActivate: mapToCanActivate([AuthGuard, AccessGuard]),
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      allowedUserTypes: [
        UserType.Vendedor,
        UserType.Comprador,
        UserType.Tecnico,
        UserType.Transportador,
        UserType.Entidad,
      ],
    },
  },
  {
    path: Path.Catalogo,
    component: CatalogoComponent,
  },
  {
    path: Path.EnlacesDeInteres,
    component: EnlacesDeInteresComponent,
  },
  {
    path: Path.ResponsabilidadSocial,
    component: ResponsabilidadSocialComponent,
  },
  {
    path: Path.Certificacion,
    component: CertificacionComponent,
  },
  {
    path: Path.Noticias,
    component: NoticiasComponent,
  },
  {
    path: Path.Contactenos,
    component: ContactenosComponent,
  },
  {
    path: Path.CarroCompras,
    loadChildren: () =>
      import("./carro-compras/carro-compras.module").then(
        (m) => m.CarroComprasModule
      ),
    component: CarroComprasComponent,
    canActivate: [AuthGuard, AccessGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: `${Path.DetalleDeProducto}/:id`,
    component: DetalleDeProductoComponent,
  },
  {
    path: "**",
    pathMatch: "full",
    component: IndexComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, routerOptions),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: manualLoaderFactory,
        deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient],
      },
      defaultLangFunction: () => "es",
    }),
  ],
  exports: [RouterModule, LocalizeRouterModule],
})
export class AppRoutingModule {}
