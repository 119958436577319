export enum Path {
  Home = "index",
  Auth = "auth",
  Administrador = "administrador",
  Alianzas = "alianzas",
  Aliados = "aliados",
  CalificacionUsuario = "calificacion-usuario",
  CarroCompras = "carro-compras",
  Catalogo = "catalogo",
  Categorias = "categorias",
  Certificacion = "certificacion",
  Comentarios = "comentarios",
  comentariosAdmin = "comentarios-administrador",
  ComprasUsiario = "compras-usuario",
  Contabilidad = "contabilidad",
  Contactenos = "contactenos",
  CrearProducto = "crear-producto",
  CrearServicio = "crear-servicio",
  DatosPersonales = "datos-personales",
  DetalleDeProducto = "detalle-de-producto",
  EditarPerfil = "editar-perfil",
  EnlacesDeInteres = "enlaces-de-interes",
  estadisticasAdmin = "estadisticas-administrador",
  Permisos = "permisos",
  facturacionAdmin = "facturacion-administrador",
  Facturador = "facturador",
  Favoritos = "favoritos",
  Fredplanet = "fredplanet",
  Login = "login",
  Noticias = "publicaciones",
  PerfilUsuarios = "perfil-usuarios",
  Publicidad = "publicidad",
  publicidadAdmin = "publicidad-administrador",
  RecuperarContrasena = "recuperar-contrasena",
  RegistroCompradorNatural = "registro-comprador-natural",
  RegistroPersonaNatural = "registro-persona-natural",
  RegistroTecnico = "registro-tecnico",
  RegistroTransportador = "registro-transportador",
  RegistroEntidad = "registro-entidad",
  ResponsabilidadSocial = "corporation",
  Revistas = "revistas",
  SeleccionDeRegistro = "seleccion-de-registro",
  ServiciosUsuario = "servicios-usuario",
  Subasta = "información-subasta",
  TreeCoinUser = "tree-coin",
  usuariosAdmin = "usuarios-administrador",
  Validador = "validador",
  Ventas = "ventas",
  Especies = "especies",
  Infosubasta = "subasta",
  Licencias = "licencias",
  Reportes = "reportes",
}
