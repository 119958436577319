import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { NgChartsModule } from "ng2-charts";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { provideFirestore, getFirestore } from "@angular/fire/firestore";
import { provideStorage, getStorage } from "@angular/fire/storage";
import { provideAuth, getAuth } from "@angular/fire/auth";
import { getFunctions, provideFunctions } from "@angular/fire/functions";
import { NgxsModule } from "@ngxs/store";
import { AdminModule } from "../app/admin/admin.module";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxPaginationModule } from "ngx-pagination";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { CommonModule } from "@angular/common";
import { environment } from "src/environments/environment";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ShareButtonsModule } from "ngx-sharebuttons/buttons";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { ShareIconsModule } from "ngx-sharebuttons/icons";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { RouterModule } from "@angular/router";
import { AdminComponent } from "./admin/admin.component";
import { AlianzasComponent } from "./alianzas/alianzas.component";
import { NavBarComponent } from "./nav-bar/nav-bar.component";
import { rootState } from "./state";
import { ApplicationPipesModule } from "./application-pipes/application-pipes-module";
import { NavBarBottomComponent } from "./nav-bar-bottom/nav-bar-bottom.component";
import { FooterComponent } from "./footer/footer.component";
import { ValidadorComponent } from "./validador/validador.component";
import { BtnRegistroComponent } from "./btn-registro/btn-registro.component";
import { IndexComponent } from "./index/index.component";
import { ProductoComponent } from "./producto/producto.component";
import { ResponsabilidadSocialComponent } from "./responsabilidad-social/responsabilidad-social.component";
import { ProgresoUploadComponent } from "./progreso-upload/progreso-upload.component";
import { NoticiasComponent } from "./noticias/noticias.component";
import { ModalAuthComponent } from "./noticias/modal-auth/modal-auth.component";
import { ComponentesGlobalesModule } from "./componentes-globales/componentes-globales.module";
import { PerfilUsuariosModule } from "./perfil-usuarios/perfil-usuarios.module";
import { ContactenosComponent } from "./contactenos/contactenos.component";
import { EnlacesDeInteresComponent } from "./enlaces-de-interes/enlaces-de-interes.component";
import { DetalleDeProductoComponent } from "./detalle-de-producto/detalle-de-producto.component";
import { CertificacionComponent } from "./certificacion/certificacion.component";
import { CategoriasComponent } from "./categorias/categorias.component";
import { CatalogoComponent } from "./catalogo/catalogo.component";
import { FiltroComponent } from "./catalogo/filtro/filtro.component";

export const createTranslateLoader = (
  http: HttpClient
): TranslateHttpLoader => {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
};

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AlianzasComponent,
    NavBarComponent,
    NavBarBottomComponent,
    FooterComponent,
    ValidadorComponent,
    BtnRegistroComponent,
    IndexComponent,
    ProductoComponent,
    ResponsabilidadSocialComponent,
    ProgresoUploadComponent,
    NoticiasComponent,
    ModalAuthComponent,
    ContactenosComponent,
    EnlacesDeInteresComponent,
    DetalleDeProductoComponent,
    CertificacionComponent,
    CategoriasComponent,
    CatalogoComponent,
    FiltroComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    provideAuth(() => getAuth()),
    provideFunctions(() => getFunctions()),
    CommonModule,
    NgxsModule.forRoot(rootState, {
      developmentMode: !environment.production,
      selectorOptions: {
        suppressErrors: false,
        injectContainerState: false,
      },
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    HttpClientModule,
    NgbModule,
    NgxPaginationModule,
    ApplicationPipesModule,
    NgChartsModule,
    ShareButtonsModule.withConfig({
      debug: !environment.production,
    }),
    ShareIconsModule,
    TranslateModule.forRoot({
      defaultLanguage: "es",
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AdminModule,
    ComponentesGlobalesModule,
    PerfilUsuariosModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
