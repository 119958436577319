<div class="seccion">
  <!-- About us -->
  <h2 class="titulo-seccion">Red Forestal</h2>
  <p class="parrafo-seccion">
    {{ "empresa.parrafo1" | translate }}
  </p>
  <!-- Research Group -->
  <h2 class="titulo-seccion">{{ "empresa.researhGoup" | translate }}</h2>
  <p class="parrafo-seccion">
    {{ "empresa.parrafo2" | translate }}
  </p>
  <ol>
    <li>{{ "empresa.bioenergi" | translate }}</li>
    <li>{{ "empresa.bioproducts" | translate }}</li>
    <li>{{ "empresa.trade" | translate }}</li>
    <li>{{ "empresa.industry" | translate }}</li>
    <li>{{ "empresa.logistic" | translate }}</li>
    <li>{{ "empresa.monitoring" | translate }}</li>
  </ol>
  <p class="parrafo-seccion">
    {{ "empresa.parrafo3" | translate }}
  </p>
  <div class="contenedor-img">
    <img
      src="../../assets/imagenes/InvestigacionBaner.webp"
      class="img-fluid"
    />
  </div>
  <!--------- Projects ------->
  <h2 class="titulo-seccion mt-5">{{ "empresa.title_projec" | translate }}</h2>
  <!--------- redforestal.com Project ------->
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4">
        <iframe
          class="video-youtube"
          src="https://www.youtube.com/embed/OCHof8KDrRI?si=v9Zpj4HlE8JEE6go"
          title="YouTube video player"
          allowfullscreen
        ></iframe>
      </div>
      <div class="col-md-8">
        <h5 class="mt-0">
          <b>{{ "empresa.project_01" | translate }}</b>
        </h5>
        <p class="mb-0">
          <b>{{ "empresa.year" | translate }}</b> 2021
        </p>
        <p class="mb-0">
          <b>{{ "empresa.description" | translate }}</b>
          {{ "empresa.des_01" | translate }}
        </p>
      </div>
    </div>
  </div>
  <!--------- La Canastilla Project ------->
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4 text-center">
        <img
          src="../../assets/imagenes/la_canastilla.webp"
          alt="logo app"
          class="img-app"
        />
      </div>
      <div class="col-md-8">
        <h5 class="mt-0">
          <b>La Canastilla</b>
        </h5>
        <p class="mb-0">
          <b>{{ "empresa.year" | translate }}</b> 2023
        </p>
        <p class="mb-0">
          <b>{{ "empresa.description" | translate }}</b>
          {{ "empresa.des_04" | translate }}
        </p>
      </div>
    </div>
  </div>
  <!--------- FinkApp Project ------->
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4">
        <iframe
          class="video-youtube"
          src="https://www.youtube.com/embed/oq43t3bjuoI?si=J_RiwVjwLck0jxNA"
          title="YouTube video player"
          allowfullscreen
        ></iframe>
      </div>
      <div class="col-md-8">
        <h5 class="mt-0">
          <b>{{ "empresa.project_02" | translate }}</b>
        </h5>
        <p class="mb-0">
          <b>{{ "empresa.year" | translate }}</b> 2022
        </p>
        <p class="mb-0">
          <b>{{ "empresa.description" | translate }}</b>
          {{ "empresa.des_02" | translate }}
        </p>
      </div>
    </div>
  </div>
  <!--------- WoodTracer Project ------->
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4">
        <iframe
          class="video-youtube"
          src="https://www.youtube.com/embed/AJM0-GY9vxg?si=5szo6060viyvpTBF"
          title="YouTube video player"
          allowfullscreen
        ></iframe>
      </div>
      <div class="col-md-8">
        <h5 class="mt-0">
          <b>{{ "empresa.project_03" | translate }}</b>
        </h5>
        <p class="mb-0">
          <b>{{ "empresa.year" | translate }}</b> 2023
        </p>
        <p class="mb-0">
          <b>{{ "empresa.description" | translate }}</b>
          {{ "empresa.des_03" | translate }}
        </p>
      </div>
    </div>
  </div>
  <!--ODS Section-->
  <h3 class="subtitulo-seccion mt-5 text-center">
    {{ "responsabilidad-social.objetivosDesarrollo" | translate }}
  </h3>
  <p class="parrafo-seccion">
    {{ "responsabilidad-social.programas" | translate }}
  </p>
  <div class="row">
    <div class="col">
      <img src="../../assets/imagenes/ods2.png" class="ods-img" alt="ODS2" />
    </div>
    <div class="col">
      <img src="../../assets/imagenes/ods4.png" class="ods-img" alt="ODS4" />
    </div>
    <div class="col">
      <img src="../../assets/imagenes/ods12.png" class="ods-img" alt="ODS12" />
    </div>
    <div class="col">
      <img src="../../assets/imagenes/ods13.png" class="ods-img" alt="ODS13" />
    </div>
    <div class="col">
      <img src="../../assets/imagenes/ods15.png" class="ods-img" alt="ods15" />
    </div>
    <div class="col">
      <img src="../../assets/imagenes/ods17.png" class="ods-img" alt="ods17" />
    </div>
  </div>
  <!-- Donation -->
  <h2 class="titulo-seccion mt-3">
    {{ "responsabilidad-social.donations" | translate }}
  </h2>
  <p class="parrafo-seccion">
    {{ "responsabilidad-social.paragraph1" | translate }}
  </p>
  <ol>
    <li>
      {{ "responsabilidad-social.item1" | translate }}
    </li>
    <li>
      {{ "responsabilidad-social.item2" | translate }}
    </li>
    <li>
      {{ "responsabilidad-social.item3" | translate }}
    </li>
  </ol>
  <h2 class="titulo-seccion pb-3">{{ "proyecto.bancolombia" | translate }}</h2>
  <p
    class="parrafo-seccion mt-3"
    innerHTML="{{ 'proyecto.bancolombiaParrafo1' | translate }}"
  ></p>
  <div class="text-center">
    <button type="button" class="btn btn-success btn-lg">
      <a href="https://checkout.wompi.co/l/rkYEIg" target="_blank">{{
        "responsabilidad-social.donations" | translate
      }}</a>
    </button>
  </div>
  <!--------- company recognitions ------->
  <h2 class="titulo-seccion mt-5">
    {{ "empresa.reconocimientos" | translate }}
  </h2>
  <ul class="awards">
    <li>
      <b>2012 -</b>
      <a
        href="https://www.facebook.com/watch/?v=600645197493709"
        target="_blank"
      >
        {{ "empresa.fondoEmprender" | translate }}
      </a>
    </li>
    <li><b>2012 -</b> {{ "empresa.ventures" | translate }}</li>
    <li><b>2013 -</b> {{ "empresa.colciencias" | translate }}</li>
    <li><b>2016 -</b> {{ "empresa.emprendedorLatinoamerica" | translate }}</li>
    <li><b>2016 -</b> {{ "empresa.premiosJuventud" | translate }}</li>
    <li>
      <b>2017 -</b>
      <a
        href="https://www.premioslatinoamericaverde.com/top500/?years=2017"
        target="_blank"
      >
        {{ "empresa.ranking4" | translate }}
      </a>
    </li>
    <li>
      <b>2018 -</b>
      {{ "empresa.foro" | translate }}
    </li>
    <li><b>2019 -</b> {{ "empresa.iufro" | translate }}</li>
    <li>
      <b>2020 -</b>
      <a
        href="https://www.madera21.cl/project-view/aplicativo-web-para-el-comercio-electronico-de-madera-legal-y-productos-forestales-no-maderables/"
        target="_blank"
      >
        {{ "empresa.semanaMadera" | translate }}
      </a>
    </li>
    <li>
      <b>2020 -</b>
      <a href="https://activa.catie.ac.cr/web/red-forestal/" target="_blank">
        {{ "empresa.innovature" | translate }}
      </a>
    </li>
    <li>
      <b>2021 -</b>
      <a
        href="https://titanes.noticias.caracoltv.com/nominado/juan-francisco-guzman"
        target="_blank"
      >
        {{ "empresa.titanes" | translate }}
      </a>
    </li>
    <li>
      <b>2023 -</b>
      <a
        href="https://britcham.com.co/premios-britcham-lazos-2023/"
        target="_blank"
      >
        {{ "empresa.lazos" | translate }}
      </a>
    </li>
  </ul>
  <!--------- Work Team ------->
  <div class="container-fluid">
    <h2 class="titulo-seccion mb-3">
      {{ "empresa.nuestroEquipo" | translate }}
    </h2>
    <div class="row">
      <div class="col-lg-6">
        <div class="d-sm-flex">
          <div class="flex-shrink-0">
            <img
              src="../../assets/imagenes/f_perfil_1.webp"
              class="img-profile"
              alt="..."
            />
          </div>
          <div class="flex-grow-1 ms-3">
            <h5 class="mt-0">Juan Francisco Guzmán Zabala</h5>
            <p class="mb-0">{{ "empresa.juanfCargo" | translate }}</p>
            <p class="mb-0">{{ "empresa.profesionJuanf" | translate }}</p>
            <p class="mb-0">{{ "empresa.especializacionJuanf" | translate }}</p>
            <p class="mb-0">{{ "empresa.masterJuanf" | translate }}</p>
            <p>info&#64;redforestal.com</p>
          </div>
        </div>
        <div class="d-sm-flex">
          <div class="flex-shrink-0">
            <img
              src="../../assets/imagenes/f_perfil_2.png"
              class="img-profile"
              alt="..."
            />
          </div>
          <div class="flex-grow-1 ms-3">
            <h5 class="mt-0">Juan Felipe Arenas Moreno</h5>
            <p class="mb-0">{{ "empresa.felipeCargo" | translate }}</p>
            <p class="mb-0">{{ "empresa.felipeProfesion" | translate }}</p>
            <p class="mb-0">info&#64;redforestal.com</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="d-sm-flex">
          <div class="flex-shrink-0">
            <img
              src="../../assets/imagenes/f_perfil_3.webp"
              class="img-profile"
              alt="..."
            />
          </div>
          <div class="flex-grow-1 ms-3">
            <h5 class="mt-0">David Esteban Gutierrez Guzmán</h5>
            <p class="mb-0">{{ "empresa.davidCargo" | translate }}</p>
            <p class="mb-0">{{ "empresa.davidProfesion" | translate }}</p>
            <p class="mb-0">{{ "empresa.davidMaster" | translate }}</p>
            <p class="mb-0">info&#64;redforestal.com</p>
          </div>
        </div>
        <div class="d-sm-flex">
          <div class="flex-shrink-0">
            <img
              src="../../assets/imagenes/webpLeo.webp"
              class="img-profile"
              alt="..."
            />
          </div>
          <div class="flex-grow-1 ms-3">
            <h5 class="mt-0">Leonardo Iregui Albarracín</h5>
            <p class="mb-0">{{ "empresa.leonardoCargo" | translate }}</p>
            <p class="mb-0">{{ "empresa.leonardoProfesion" | translate }}</p>
            <p class="mb-0">{{ "empresa.leonardoEsp" | translate }}</p>
            <p>info&#64;redforestal.com</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="contenedor-img">
    <h2 class="subtitulo-seccion">
      {{ "responsabilidad-social.docsFundation" | translate }}
    </h2>
    <button
      type="button"
      class="btn btn-success"
      data-bs-toggle="modal"
      data-bs-target="#modalDocumentFundacion"
    >
      {{ "responsabilidad-social.openBtn" | translate }}
    </button>
  </div>
  <app-btn-registro></app-btn-registro>
</div>
<!--Ventana emergente con documentos de la fundación red forestal-->
<div
  class="modal fade"
  id="modalDocumentFundacion"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "responsabilidad-social.docsFundation" | translate }}
        </h5>
      </div>
      <div class="modal-body">
        <ul>
          <li>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/ecommerce-red-forestal-des-1.appspot.com/o/RedForestal%2Fca%CC%81mara%20de%20comercio.pdf?alt=media&token=f4e6f2b4-fa46-443f-924e-fb03bc1009c5"
              target="_blank"
            >
              Cámara de Comercio
            </a>
          </li>
          <li>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/ecommerce-red-forestal-des-1.appspot.com/o/RedForestal%2F3.%20MODELO%20CERTIFICACION%20ART%20364-3.pdf?alt=media&token=7b10cd79-679f-4dfe-96c4-87eb994b3b36"
              target="_blank"
            >
              Certificación Junta Directiva
            </a>
          </li>
          <li>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/ecommerce-red-forestal-des-1.appspot.com/o/RedForestal%2F3.%20MODELO%20CERTIFICACION%20Numeral%2013%20del%20para%CC%81grafo%202%20del%20arti%CC%81culo%20364-5%20E.T..pdf?alt=media&token=04ec6196-3d59-4aa1-9cc1-bfdbedfe59f3"
              target="_blank"
            >
              Certificación Estatuto Tributario
            </a>
          </li>
          <li>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/ecommerce-red-forestal-des-1.appspot.com/o/RedForestal%2FActa%20Restructuracio%CC%81n.pdf?alt=media&token=2b492f20-2570-4d34-8c1a-3c67421a36b2"
              target="_blank"
            >
              Acta Restructuración
            </a>
          </li>
          <li>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/ecommerce-red-forestal-des-1.appspot.com/o/RedForestal%2FBALANCE%20INICIAL%20(2).pdf?alt=media&token=369ff4f3-27ed-4c01-be76-ae92cf4b17d5"
              target="_blank"
            >
              Balance Inicial
            </a>
          </li>
        </ul>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
          {{ "formularios.cerrar" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
