import { Injectable } from "@angular/core";
import { Tree } from "../models/tree";
import { FirebaseRequestsService } from "./firebase-requests.service";
import { where } from "firebase/firestore";

@Injectable({
  providedIn: "root",
})
export class TreeDataService {
  private collection: string = "trees";

  constructor(private firebaseRequests: FirebaseRequestsService) {}

  getAllTrees(): Promise<Tree[]> {
    return this.firebaseRequests.getCollectionFirebasePromiseWithId<Tree>(
      this.collection,
      [where("userId", "!=", "")]
    );
  }
}
