import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { forkJoin, Observable, of, Subject } from "rxjs";
import { switchMap, take, tap } from "rxjs/operators";
import { takeUntil, map, catchError } from "rxjs/operators";
import { ProductoData } from "../models/ProductoData";
import { UpdateUsuario } from "../models/UpdateProducto";
import {
  AuthenticationState,
  AuthenticationStateModel,
} from "../state/authentication-state";

// importación de servicios

import { deepCopy } from "../utils/deepCopy";
import { Path } from "../enums/Path";
import { UserType } from "../enums/UserTypes";
import { Select } from "@ngxs/store";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { User } from "@angular/fire/auth";
import { ProductData, ProductoService } from "../services/producto.service";
import { CarroComprasService } from "../services/carro-compras.service";
import { AuthService } from "../services/auth.service";
import { UserData, UserService } from "../services/user.service";
import { CalificacionService } from "../services/calificacion.service";
import { BaseFormService } from "../services/base-form.service";
import { StorageService } from "../services/storage.service";
import { ComprasService } from "../services/compras.service";
import { SubscriptionService } from "../services/subscriptions.service";
import { email } from "functions/src/mails-fn/shared/mail";
import { CalificacionyComentarios } from "../models/CalificacionyComentarios";
import { UserDataHelpers } from "../utils/getApellidosUserData";
import { SuscripcionActiva } from "../models/subscripcion.interface";
import { Compra } from "../models/compra.interface";
import { Timestamp } from "@angular/fire/firestore";

@Component({
  selector: "app-detalle-de-producto",
  templateUrl: "./detalle-de-producto.component.html",
  styleUrls: ["./detalle-de-producto.component.css"],
})
export class DetalleDeProductoComponent implements OnInit, OnDestroy {
  // Observables and Subjects
  @Select(AuthenticationState.user)
  user$!: Observable<AuthenticationStateModel["user"]>;
  @ViewChild("closeButton") closeButton!: ElementRef;
  @ViewChild("closeButtonSolicitar") closeButtonSolicitar!: ElementRef;
  @ViewChild("detailForm") detailForm: any; // referencia a formulario renderizado con detalles de producto

  filePaths: string[] = [];
  defaultThumbnailUrl: string = "assets/imagenes/noImage.png";
  formUpdate: FormGroup = new FormGroup({
    unidades: new FormControl(0),
    total: new FormControl(0),
  });

  public user: User | null = null;
  public currentUserType!: UserType[] | UserType | string;
  public userType: typeof UserType = UserType;

  // Own Props
  private unsubscriber: Subject<void> = new Subject();
  public idProducto!: string;
  public resultado!: number;
  public precio!: number;
  public productoItem!: string[];
  public producto!: ProductoData;
  public calificaciones: any = [];
  public puntuacionTotal: number = 0;
  public permitirComprar!: boolean;

  // datos vendedor
  public nombreVendedor!: string;
  public emailVendedor!: email;
  public telefonoVendedor!: string;

  constructor(
    private route: ActivatedRoute,
    private productoService: ProductoService,
    private carroComprasService: CarroComprasService,
    private authService: AuthService,
    private userService: UserService,
    private calificacionService: CalificacionService,
    private router: Router,
    private baseFormService: BaseFormService,
    private storageService: StorageService,
    private comprasService: ComprasService,
    private localize: LocalizeRouterService,
    private suscripcionSvc: SubscriptionService
  ) {
    this.formUpdate
      .get("unidades")
      ?.valueChanges.pipe(
        takeUntil(this.unsubscriber),
        tap({
          next: (cantidad: any) => {
            this.formUpdate
              .get("total")
              ?.setValue(cantidad * this.producto.precio);
          },
          error: (err) => {
            console.error(err);
          },
        })
      )
      .subscribe();
  }

  ngOnInit() {
    const { id } = this.route.snapshot.params;
    this.idProducto = id;

    // Consultar el Producto.
    this.productoService
      .getProductById(this.idProducto)
      .pipe(
        // Inicializar el formulario con los datos el Producto.
        takeUntil(this.unsubscriber),
        switchMap((producto: ProductData) => {
          this.producto = producto;
          this.getCalificaciones(producto.idVendedor);
          console.log("id usuario:", producto.idVendedor);
          return this.baseFormService.completForm.pipe(take(1));
        }),
        switchMap((form: FormGroup) => {
          form.patchValue(this.producto);
          return this.userService.getUserById(this.producto.idVendedor);
        }),
        tap({
          next: (user: UserData) => {
            this.nombreVendedor = user.nombres;
            this.emailVendedor = user.email;
            this.telefonoVendedor = user.telefono;
          },
          error: (err) => {
            console.error(err);
          },
        })
      )
      .subscribe();

    this.user$
      .pipe(
        takeUntil(this.unsubscriber),
        switchMap((user: User | null) => {
          this.user = user;
          if (user) {
            this.getSubscripcion(user.uid);
            return this.userService.getUserData(user.uid);
          }

          return of(null);
        }),
        tap({
          next: (user: UserData | null) => {
            if (user) {
              const currentUserData: UserData = user;
              this.currentUserType = currentUserData.tipo;
            }
          },
          error: (err) => {
            console.error(err);
          },
        })
      )
      .subscribe();

    this.getFilePaths();
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  async getFilePaths(): Promise<void> {
    this.filePaths = await this.productoService.getAllImages(this.idProducto);
  }

  deleteFalsy(producto: ProductoData): ProductoData {
    const datos: ProductoData = { ...producto };
    Object.keys(datos).map((key: string) => {
      !datos[key] && delete datos[key];
    });

    return datos;
  }

  async addFormCarro(): Promise<void> {
    if (
      this.formUpdate.get("unidades")?.value <=
      this.producto.disponibilidadVenta
    ) {
      const producto: UpdateUsuario = {
        unidades: this.formUpdate.value.unidades,
      };
      this.producto = this.deleteFalsy(this.producto);

      const productoCarro: any = {
        cantidad: producto.unidades,
        disponibilidad: this.producto.disponibilidadVenta,
        idProducto: this.idProducto,
        valorTotal: this.formUpdate.get("total")?.value,
        ...this.producto,
      };
      try {
        const productId: string =
          await this.carroComprasService.addCarro(productoCarro);
        const currentUser: User | null = this.authService.getCurrentUser();

        if (currentUser) {
          const currentUserData: UserData = await this.userService.getUserData(
            currentUser.uid
          );
          const newUserData: UserData = deepCopy(currentUserData);

          if (!newUserData.carroCompras) {
            newUserData.carroCompras = [];
          }
          newUserData.carroCompras.push(productId);
          this.userService.addCarroToUser(currentUser.uid, productId);

          this.router.navigate([
            this.localize.parser.currentLang,
            Path.CarroCompras,
          ]);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      window.alert("Unidades solicitadas no disponibles");
    }
  }

  getCalificaciones(userId: string): void {
    this.calificacionService
      .getUserCalificaciones(userId)
      .pipe(
        takeUntil(this.unsubscriber),
        switchMap((calificaciones: CalificacionyComentarios[]) => {
          return forkJoin(
            calificaciones.map((calif: CalificacionyComentarios) => {
              return this.storageService
                .getStoreUrlImageObservable(
                  `fotoPerfilUser/${calif.idCalificador}_500x500`
                )
                .pipe(
                  catchError(() => {
                    return this.storageService.getStoreUrlImagePromise(
                      `fotoPerfilUser/${calif.idCalificador}`
                    );
                  }),
                  map((url: string) => {
                    calif.imagen = url;
                    delete calif.idCalificado;
                    // delete calif.idCompra;
                    // delete calif.idItem;
                    return calif;
                  })
                );
            })
          );
        }),
        tap({
          next: (calificaciones: CalificacionyComentarios[]) => {
            if (calificaciones.length) {
              let cont: number = 0;
              this.calificaciones = calificaciones;
              calificaciones.map((calif) => {
                cont += calif.puntuacion;
              });

              this.puntuacionTotal = Math.round(cont / calificaciones.length);
            }
          },
          error: (err) => {
            console.error(err);
          },
        })
      )
      .subscribe();
  }

  getComprador(id: string): Observable<string> {
    const profesional: Observable<string> = this.userService
      .getUserById(id)
      .pipe(
        map((user) => {
          return `${user.nombres} ${UserDataHelpers.getApellidos(user)}`;
        })
      );
    return profesional;
  }

  async contactarVendedor(): Promise<void> {
    try {
      this.producto = this.deleteFalsy(this.producto);
      const producto: UpdateUsuario = {
        unidades: this.formUpdate.value.unidades,
      };

      if (this.user) {
        // Se deja any ya que this.Producto tiene incompatibilidades para tipar con otros modelos.
        const productoCarro: any = {
          cantidad: producto.unidades,
          disponibilidad: this.producto.disponibilidadVenta || 0,
          idProducto: this.idProducto,
          valorTotal: this.formUpdate.get("total")?.value,
          estado: "pendiente",
          ...this.producto,
        };

        const compra: Compra = {
          idComprador: this.user.uid,
          fecha: Timestamp.now(),
          productos: [{ ...productoCarro }],
        };
        await this.comprasService.addCompra(compra);
        await this.productoService.updateProduct(
          {
            disponibilidadVenta:
              this.producto.disponibilidadVenta - producto.unidades,
          },
          this.idProducto
        );
        this.closeButtonSolicitar.nativeElement.click();
        this.formUpdate.get(["unidades"])?.reset();
      }
    } catch (e) {
      console.error(e);
    }
  }

  traerPedidosMes(idComprador: string): void {
    const month: number = new Date().getMonth();
    this.comprasService
      .getPedidoByMonth(idComprador, month)
      .pipe(
        takeUntil(this.unsubscriber),
        tap({
          next: (pedidos: Compra[]) => {
            this.permitirComprar = pedidos.length < 0;
          },
          error: (err) => {
            console.error(err);
          },
        })
      )
      .subscribe();
  }

  getSubscripcion(idUsuario: string): void {
    this.suscripcionSvc
      .getActiveSubscription(idUsuario)
      .then((r: SuscripcionActiva) => {
        this.permitirComprar = r?.suscripcionActual ? true : false;
        if (!this.permitirComprar) {
          this.traerPedidosMes(idUsuario);
        }
      })
      .catch((err) => console.error(err));
  }
}
