<div class="text-center mt-5 mb-3" *ngIf="user; else login">
  <div *ngIf="!isSubscribed">
    <h2 class="texto-titulo">
      {{ "btn-registro.suscribeteParaComprar" | translate }}
    </h2>
  </div>
</div>
<ng-template #login>
  <div class="text-center mt-5 mb-3">
    <h2 class="texto-titulo">
      {{ "btn-registro.registrateComprar" | translate }}
    </h2>
    <button
      type="button"
      class="btn btn-warning btn-lg"
      [routerLink]="[path.Auth, path.SeleccionDeRegistro] | langRoute | async"
    >
      {{ "btn-registro.registrate" | translate }}
    </button>
  </div>
</ng-template>
