import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import maplibregl, {
  LngLatLike,
  Map,
  MapGeoJSONFeature,
  Marker,
  NavigationControl,
} from "maplibre-gl";
import { Tree } from "../models/tree";
import { TreeDataService } from "../services/tree-data.service";
import { Point } from "geojson";

@Component({
  selector: "app-fredplanet",
  templateUrl: "./fredplanet.component.html",
  styleUrls: ["./fredplanet.component.css"],
})
export class FredplanetComponent implements AfterViewInit, OnDestroy {
  @ViewChild("map")
  // map variables
  private mapRef!: ElementRef<HTMLElement>;
  map!: Map;
  marker: Marker | undefined;
  treeData: Tree[] = [];
  treeDataGeoJson: any = [];

  constructor(private treeDataService: TreeDataService) {
    this.getTrees();
  }

  /** get treeData from firbease database */
  async getTrees(): Promise<void> {
    try {
      this.treeData = await this.treeDataService.getAllTrees();
      // Transform treeData to GeoJSON
      this.dataToGeoJson();
    } catch (e) {
      console.error(e);
    }
  }

  /** Transform treeData to GeoJSON */
  dataToGeoJson() {
    const getActualName = (baseName: string, addedName?: string): string => {
      return baseName === "agregar" && addedName ? addedName : baseName;
    };
    const buildImageHtml = (src?: string): string => {
      return src
        ? `<img src="${src}" width="100%" alt="Foto" />`
        : "<br>Sin Foto<br>";
    };
    this.treeDataGeoJson = this.treeData.map((tree) => {
      const family = getActualName(tree.familyName, tree.familyNameAdded);
      const gender = getActualName(tree.genderName, tree.genderNameAdded);
      const species = getActualName(tree.speciesName, tree.speciesNameAdded);
      const description = `
      <b>Tipo:</b>${tree.growthHabit}<br>
      <b>Familia:</b>${family}<br>
      <b>Género:</b>${gender}<br>
      <b>Especie:</b>${species}<br>
      <b>Altura(m):</b>${tree.treeTotalHeight}<br>
      <b>CAP(cm):</b>${tree.circumferenceChestHeight}<br>
      <div class="row text-center">
        <div class="col">
          <b>Corteza</b>${buildImageHtml(tree.barkPhoto)}
        </div>
        <div class="col">
          <b>Flor</b>${buildImageHtml(tree.flowerPhoto)}
        </div>
        <div class="col">
          <b>Fruto</b>${buildImageHtml(tree.fruitPhoto)}
        </div>
        <div class="col">
          <b>Hojas</b>${buildImageHtml(tree.leafPhoto)}
        </div>
      </div>
    `;
      return {
        type: "Feature",
        properties: {
          description: description,
          icon: "tree",
        },
        geometry: {
          type: "Point",
          coordinates: [tree.location.longitude, tree.location.latitude],
        },
      };
    });
  }

  ngAfterViewInit(): void {
    const initialState = { lng: -65.072542, lat: -2.963941, zoom: 3 };

    this.map = new Map({
      container: this.mapRef.nativeElement, // container id
      style:
        "https://api.maptiler.com/maps/basic-v2/style.json?key=xfyTzqkO4FElb6P7pdGw", // style URL
      center: [initialState.lng, initialState.lat], // starting position [lng, lat]
      zoom: initialState.zoom, // starting zoom
    });
    this.map.addControl(new NavigationControl({}), "bottom-left");
    this.map.addControl(new maplibregl.FullscreenControl(), "bottom-left");

    this.map.on("load", () => {
      this.addTree();
    });
  }

  addTree() {
    this.map.addSource("places", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: this.treeDataGeoJson,
      },
    });

    // Add a layer showing the places.
    this.map.addLayer({
      id: "places",
      type: "circle",
      source: "places",
      paint: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "circle-radius": 6,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "circle-color": "#ff0000", // Color de los círculos
      },
    });

    // When a click event occurs on a feature in the places layer, open a popup at the
    // location of the feature, with description HTML from its properties.
    this.map.on("click", "places", (e) => {
      const coordinates = (
        (e.features as MapGeoJSONFeature[])[0].geometry as Point
      ).coordinates.slice();
      const description = (e.features as MapGeoJSONFeature[])[0].properties[
        "description"
      ];

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      new maplibregl.Popup()
        .setLngLat(coordinates as LngLatLike)
        .setHTML(description)
        .setMaxWidth("350px")
        .addTo(this.map);
    });

    // Change the cursor to a pointer when the mouse is over the places layer.
    this.map.on("mouseenter", "places", () => {
      this.map.getCanvas().style.cursor = "pointer";
    });

    // Change it back to a pointer when it leaves.
    this.map.on("mouseleave", "places", () => {
      this.map.getCanvas().style.cursor = "";
    });
  }

  ngOnDestroy() {
    this.map?.remove();
  }
}
