import { Component, Input } from "@angular/core";

@Component({
  selector: "app-limite-pedidos",
  templateUrl: "./limite-pedidos.component.html",
  styleUrls: ["./limite-pedidos.component.css"],
})
export class LimitePedidosComponent {
  @Input() productos!: boolean;

  constructor() {}
}
